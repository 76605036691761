import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import FlexColumn from "../../components/FlexColumn";
import { MOBILE } from "../../Constants";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../api/axios";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  max-width: ${MOBILE};
  width: 100%;
  padding: 1.88rem;
`;

const Logo = styled.img`
  width: 4.4805rem;
  height: 1.375rem;
  margin-bottom: 1.88rem;
`;

const Heading = styled.h1`
  color: #000;
  font-family: Pretendard Variable;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 2.25rem */
  letter-spacing: -0.03125rem;
  margin-bottom: 0.81rem;
`;

const Text = styled.p`
  color: #333;
  font-family: Pretendard Variable;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.3125rem */
  letter-spacing: -0.03125rem;
  margin-bottom: 2.5rem;
`;

// const Banner = styled.img`
//   width: 19.6875rem;
//   height: 8rem;
// `;
const Banner = styled.div`
  width: 100%;
  height: 8rem;
  border-radius: 0.9375rem;
  padding: 0 1.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ bgColor }) => bgColor};
  & .subtitle {
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    color: ${({ color }) => color};
  }
  & .title {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 1.75rem */
    letter-spacing: -0.03125rem;
    color: ${({ theme }) => theme.grayScaleColor.black};
  }

  & img {
    width: 5.3125rem;
    height: 5.3125rem;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 3.44rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #333333;
  font-family: Pretendard Variable;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem; /* 125% */
  letter-spacing: -0.03125rem;
  color: white;
  border: none;
  border-radius: 0.4375rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
`;

const ShareText = styled.div`
  color: #666;

  text-align: center;

  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.21875rem */
  letter-spacing: -0.03125rem;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
  padding-top: 12px;
`;

const FooterText = styled.span`
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.02em;
  color: #aaaaaa;
`;

const FooterLogo = styled.img`
  width: 16px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>ⓒBLOOM COMPANY</FooterText>
      <FooterLogo
        src={`${process.env.PUBLIC_URL}/assets/itree/itree_bloom_logo.png`}
      />
    </FooterContainer>
  );
};

const GuideLink = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.get("id") || 0;
  const hasParams = params.size !== 0;

  const block1Num =
    !hasParams && !params.get("b1") ? 41 : Number(params.get("b1"));
  const block2Num =
    !hasParams && !params.get("b2") ? 14 : Number(params.get("b2"));
  const block3Num =
    !hasParams && !params.get("b3") ? 6 : Number(params.get("b3"));
  const block4Num =
    !hasParams && !params.get("b4") ? 41 : Number(params.get("b4"));
  const block5Num =
    !hasParams && !params.get("b5") ? 14 : Number(params.get("b5"));
  const block6Num =
    !hasParams && !params.get("b6") ? 6 : Number(params.get("b6"));
  const [blocks, setBlocks] = useState([]);
  const smile = !hasParams ? true : params.get("smile");
  const padlet = params.get("padlet");
  const mentimeter = params.get("mentimeter");
  const checkup = params.get("checkup");

  const handleClick = (e, number) => {
    window.open(`https://app.maum.fit/training/${number}`);
  };

  const convertBGColor = (theme) => {
    switch (theme) {
      case "회복탄력성":
        return "#FFF8E7";
      case "마음챙김":
        return "#EBF8F9";
      case "긍정정체성":
        return "#F8F3FF";
      case "긍정리더십":
        return "#FFF3EB";
      case "내재동기":
        return "#F8F9DF";
      case "자기효능감":
        return "#FDF1F6";
      case "활력":
        return "#EBF1FA";
      default:
        return "#FFF8E7";
    }
  };
  const convertColor = (theme) => {
    switch (theme) {
      case "회복탄력성":
        return "#FFC842";
      case "마음챙김":
        return "#00C0E0";
      case "긍정정체성":
        return "#B782B9";
      case "긍정리더십":
        return "#FF812C";
      case "내재동기":
        return "#CBD227";
      case "자기효능감":
        return "#CC2B69";
      case "활력":
        return "#7CA1D4";
      default:
        return "#FFC842";
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get(
        `/block/workshop?b1=${block1Num}&b2=${block2Num}&b3=${block3Num}&b4=${block4Num}&b5=${block5Num}&b6=${block6Num}`
      );
      setBlocks(res.data.data.blocks);
    };
    fetch();
  }, []);

  if (blocks.length === 0) {
    return <div />;
  }
  const block1 = blocks.find((b) => b.number === Number(block1Num));
  const block2 = blocks.find((b) => b.number === Number(block2Num));
  const block3 = blocks.find((b) => b.number === Number(block3Num));
  const block4 = blocks.find((b) => b.number === Number(block4Num));
  const block5 = blocks.find((b) => b.number === Number(block5Num));
  const block6 = blocks.find((b) => b.number === Number(block6Num));

  const handleShareClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "내 마음 관리법 링크",
          url: window.location.href,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <Container>
      <Helmet>
        <title>맘핏 | 내 마음 관리법 링크</title>
        <meta name="theme-color" content="#fff" />
      </Helmet>
      <Wrapper>
        <Logo src={`${process.env.PUBLIC_URL}/assets/guide-link/logo.png`} />
        <Heading>
          하루 10분
          <br />내 마음 관리법
        </Heading>
        <Text>
          아래 각 배너를 통해,
          <br />
          손쉽게 내 마음을 관리할 수 있어요 💪
        </Text>

        <FlexColumn style={{ gap: "1rem" }}>
          {smile && (
            <Banner
              bgColor={"#E6FAF6"}
              color={"#00DBB1"}
              onClick={() =>
                window.open(`https://app.maum.fit/smile-challenge`)
              }
            >
              <FlexColumn>
                <p className="subtitle">Smile Challenge</p>
                <p className="title">찐미소 챌린지</p>
              </FlexColumn>
              <img
                src={`${process.env.PUBLIC_URL}/assets/guide-link/smile_icon.png`}
                alt={"smile logo"}
              />
            </Banner>
          )}
          {mentimeter && (
            <Banner
              bgColor={"#F8F8F8"}
              color={"#196CFF"}
              onClick={() => window.open(`https://www.menti.com/${mentimeter}`)}
            >
              <FlexColumn>
                <p className="subtitle">Intro</p>
                <p className="title">익명으로 투표하기</p>
              </FlexColumn>
              <img
                src={`${process.env.PUBLIC_URL}/assets/guide-link/mentimeter.png`}
                alt={"mentimeter logo"}
              />
            </Banner>
          )}
          {padlet && (
            <Banner
              bgColor={"#F6F1FF"}
              color={"#9466E8"}
              onClick={() =>
                window.open(`https://padlet.com/BLOOMCOMPANY/${padlet}`)
              }
            >
              <FlexColumn>
                <p className="subtitle">Padlet</p>
                <p className="title">생각 공유하기</p>
              </FlexColumn>
              <img
                src={`${process.env.PUBLIC_URL}/assets/guide-link/padlet.png`}
                alt={"padlet logo"}
              />
            </Banner>
          )}
          {checkup && (
            <Banner
              bgColor={"#E6FAF6"}
              color={"#00DBB1"}
              onClick={() =>
                window.open(`https://app.maum.fit/checkup/v2/test?id=0`)
              }
            >
              <FlexColumn>
                <p className="subtitle">Check Up</p>
                <p className="title">마음 체크업</p>
              </FlexColumn>
              <img
                src={`${process.env.PUBLIC_URL}/assets/guide-link/checkup.png`}
                alt={"checkup logo"}
              />
            </Banner>
          )}
          {[block1, block2, block3, block4, block5, block6]
            .filter((item) => item)
            .map((block, index) => (
              <Banner
                key={index}
                bgColor={convertBGColor(block.theme)}
                color={convertColor(block.theme)}
                onClick={(e) => handleClick(e, block.number)}
              >
                <FlexColumn>
                  <p className="subtitle">#{index + 1}</p>
                  <p className="title">{block.title}</p>
                </FlexColumn>
                <img
                  src={`${block.iconUrl}`}
                  alt={"mentimeter logo"}
                  style={{ width: "5.3125rem", height: "5.3125rem" }}
                />
              </Banner>
            ))}
        </FlexColumn>
        <Button onClick={handleShareClick}>페이지 공유하기</Button>
        <ShareText
          onClick={() =>
            window.open("https://www.youtube.com/@wellnesstudio5", "_blank")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
          >
            <g clip-path="url(#clip0_7028_12762)">
              <path
                d="M20.1414 3.16321C19.9104 2.29887 19.2295 1.61792 18.3646 1.38642C16.7978 0.966415 10.5136 0.966415 10.5136 0.966415C10.5136 0.966415 4.23003 0.966415 2.66267 1.38642C1.79833 1.61736 1.11739 2.2983 0.885881 3.16321C0.465881 4.73 0.465881 8 0.465881 8C0.465881 8 0.465881 11.2694 0.885881 12.8368C1.11682 13.7011 1.79777 14.3821 2.66267 14.6136C4.22947 15.0336 10.5136 15.0336 10.5136 15.0336C10.5136 15.0336 16.7972 15.0336 18.3646 14.6136C19.2289 14.3826 19.9098 13.7017 20.1414 12.8368C20.5614 11.27 20.5614 8 20.5614 8C20.5614 8 20.5614 4.73057 20.1414 3.16321ZM8.44815 10.9575V4.92925L13.6687 7.9434L8.44815 10.9575Z"
                stroke="#666666"
                strokeWidth="0.933962"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_7028_12762">
                <rect
                  width="21.0283"
                  height="15"
                  fill="white"
                  transform="translate(0 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          <p>웰니스튜디오 | 직장인을 위한 멘탈 웰니스</p>
        </ShareText>
        <Footer />
      </Wrapper>
    </Container>
  );
};

export default GuideLink;
